import React from "react"
import tw from "twin.macro"
import { graphql } from "gatsby"
import jobsDec from "@images/singlejobsdec.png"
import parse from "html-react-parser"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { useBreakpoint } from "@hooks/useBreakpoint"
import { Footer } from "@components/root/Footer"
import gsap from "gsap"
import ScrollTrigger from "gsap/ScrollTrigger"
import ScrollSmoother from "gsap/ScrollSmoother"
import DarkModeBar from "@elements/DarkModeBar"
import { SEO } from "@root/Seo"

const Main = tw.div`mx-auto pt-spacing-100 lg:pt-spacing-150 2xl:pt-spacing-250 3xl:pt-spacing-350 bg-yellow dark:bg-black overflow-hidden relative`
const BackgroundWrapper = tw.div`fixed z-0 -top-20 left-0 right-0 w-full bottom-0`

const ContentWrapper = tw.div`relative z-10 pt-32`
const PageHead = tw.div`z-10 text-black dark:text-white px-spacing-15 md:px-offset-xs 2xl:px-offset-master 4xl:px-spacing-75 pb-spacing-xs mx-auto`
const Subline = tw.p`text-20px/140 md:text-22px/140 lg:text-28px/140 3xl:text-32px/140`
const Headline = tw.p`text-50px/115 md:text-70px/115 lg:text-100px/115 4xl:text-160px/115 w-full md:w-4/5 lg:w-3/4 4xl:w-2/3`

const GridContainer = tw.div`flex flex-col md:flex-row 2xl:grid 2xl:grid-cols-2 gap-x-spacing-master mt-spacing-xs lg:mt-spacing-master w-full`
const Description = tw.p` md:pb-0 text-20px/140 md:text-22px/140 lg:text-28px/140 3xl:text-32px/140 sm:max-w-[500px] lg:max-w-[650px] 2xl:w-full 2xl:max-w-[650px] 4xl:max-w-[900px]`
const MoreInfo = tw.div`text-20px/140 md:text-22px/140 lg:text-28px/140 3xl:text-32px/140 w-auto 2xl:w-full 2xl:max-w-[650px] 4xl:max-w-[900px] pb-spacing-xs sm:pb-spacing-master`
const PinkSection = tw.div`w-full px-spacing-15 py-spacing-xs md:px-spacing-xs md:py-spacing-40 lg:p-spacing-master text-white bg-pink dark:bg-black dark:text-white dark:border-t dark:border-b dark:border-gray-300 text-16px/150 lg:text-20px/140 3xl:text-32px/140 text-center`

const BoxWrapper = tw.div`mx-auto mb-spacing-master`
const BoxContent = tw.div`max-w-screen-xl mx-auto 3xl:mr-0 3xl:ml-auto grid lg:grid-cols-2 gap-offset-xs `

const filterStrings = (str, color) => {
  const toFilter = str.match(/(\p{Lu})+(\p{Lu}\p{Lu})/gu)
  if (toFilter?.length > 0) {
    toFilter.forEach(word => {
      str = str.replace(word, `<span className="font-primary ${color === "pink" ? "text-yellow dark:text-gray-500" : ""}">${word}</span>`)
    })
    return str
  }
  return str
}

export default function JobsTemplate({ data }) {
  const main = data.main
  const { media } = useBreakpoint()

  const filterCategories = type => {
    return main.jobsLocation.nodes.map(location => {
      if (type === "name") return location.name
      if (type === "slug") return location.slug
      return ""
    })
  }

  const smoother = React.useRef()

  const sectionRef = React.useRef()
  const tl = React.useRef()
  const q = gsap.utils.selector(sectionRef)
  gsap.registerPlugin(ScrollTrigger, ScrollSmoother)

  const gsapInit = () => {
    tl.current && tl.current.progress(0).kill()
    tl.current = gsap.timeline({
      scrollTrigger: {
        trigger: sectionRef.current,
        start: "top top",
        end: "bottom top",
        scrub: 4,
      },
    })
    tl.current.to(q(".bg-wrapper"), { duration: 2, y: "-=250px", ease: "power1.easeInOut" }, 0)

    if (ScrollTrigger.isTouch !== 1) {
      smoother.current = ScrollSmoother.create({
        smooth: 0.8,
        effects: false,
        smoothTouch: 0.1,
      })
    }
  }

  React.useEffect(() => {
    if (sectionRef.current) gsapInit()

    return () => {
      if (ScrollTrigger.isTouch !== 1) smoother.current.kill()
    }
  }, [])

  return (
    <>
      <DarkModeBar />
      <div id="smooth-wrapper">
        <div id="smooth-content">
          <Main ref={sectionRef}>
            <BackgroundWrapper className="bg-wrapper dark:hidden">
              <img className="object-cover w-[150%] h-auto max-w-none" src={jobsDec} alt="" />
            </BackgroundWrapper>
            <ContentWrapper>
              <PageHead>
                <Headline>{parse(filterStrings(main.title))}</Headline>
                <Subline>(w/m/x)</Subline>
                <GridContainer>
                  <MoreInfo>in {filterCategories("name").join(", ")}</MoreInfo>
                  <Description>{main.postTypeJobs.description}</Description>
                </GridContainer>
              </PageHead>
              {main.postTypeJobs.boxContent?.length > 0 && (
                <BoxWrapper>
                  <BoxContent>
                    <div className="flex flex-col mx-spacing-15 md:mx-spacing-xs lg:mx-0 lg:items-end left-column">
                      <PinkBox className="max-w-[500px]" color="pink" image={null} content={main.postTypeJobs.boxContent[0]} />
                      {main.postTypeJobs.boxContent[2] && (media === "isScreenXXXL" || media === "isScreenXXXXL") && (
                        <ImageBox className="mt-spacing-xs" color="white" image={true} content={main.postTypeJobs.boxContent[2]} />
                      )}
                    </div>
                    {main.postTypeJobs.boxContent[1] && (
                      <div className="right-column mx-spacing-15 md:mx-spacing-xs lg:mx-0">
                        <PinkBox className="max-w-[500px]" color="pink" image={null} content={main.postTypeJobs.boxContent[1]} />
                      </div>
                    )}
                    {main.postTypeJobs.boxContent[2] && media !== "isScreenXXXL" && media !== "isScreenXXXXL" && (
                      <ImageBox className="md:flex lg:col-start-2 " color="white" image={true} content={main.postTypeJobs.boxContent[2]} />
                    )}
                  </BoxContent>
                </BoxWrapper>
              )}
              {main.postTypeJobs.pinkSection && <PinkSection>{parse(main.postTypeJobs.pinkSection)}</PinkSection>}
              <React.Suspense fallback={null}>
                {main.blocks.map((module, index) => {
                  if (module.__typename.includes("WpCore")) return ""
                  const LoadableModuleBlock = React.lazy(() =>
                    import(`@modules/${module.__typename}`).catch(() => ({ default: () => <div className="text-black dark:text-white">{module.__typename} not prepared.</div> }))
                  )
                  return <LoadableModuleBlock data={module} template="jobs" key={`${module.__typename}-${index}`} />
                })}
              </React.Suspense>
            </ContentWrapper>
          </Main>
          <Footer variant="main" />
        </div>
      </div>
    </>
  )
}

const PinkBox = ({ content, className }) => {
  return (
    <div className={`relative bg-pink dark:bg-white box py-spacing-30 px-spacing-20 2xl:py-spacing-master 2xl:px-spacing-xs lg:max-w-[340px] 2xl:max-w-[432px] ${className}`}>
      <div className="text-black dark:text-black headline text-32px/115 2xl:text-50px/115 mb-spacing-xs">{parse(filterStrings(content.headline, "pink"))}</div>
      <div className="description text-16px/150 lg:text-20px/140">{parse(content.description)}</div>
    </div>
  )
}
const ImageBox = ({ image, content, className }) => {
  return (
    <div className={`relative bg-white box mx-spacing-15 md:mr-spacing-master lg:mr-0 lg:py-spacing-30 lg:px-spacing-20 2xl:py-spacing-master 2xl:px-spacing-xs lg:max-w-[340px] 2xl:max-w-[432px] ${className}`}>
      {image && <GatsbyImage className="lg:!absolute aspect-square md:aspect-auto md:h-full md:w-1/2 lg:w-[630px] lg:-translate-x-full lg:left-0 lg:top-0" image={getImage(content.image.localFile)} alt="" />}
      <div className="md:w-1/2 content py-spacing-30 px-spacing-20 lg:p-0 lg:w-auto">
        <div className="text-black headline text-32px/115 2xl:text-50px/115 mb-spacing-xs">{parse(filterStrings(content.headline, "white"))}</div>
        <div className="description text-16px/150 lg:text-20px/140">{parse(content.description)}</div>
      </div>
    </div>
  )
}

export const Head = ({ data }) => <SEO seoData={data.main.seo} />

export const query = graphql`
  query ($page: String) {
    main: wpJobs(id: { eq: $page }) {
      title
      id
      uri
      content
      contentTypeName
      locale {
        locale
      }
      translated {
        slug
        uri
      }
      translations {
        href
      }
      ...StaticFieldsQuery
      blocks {
        __typename
        ...AllBlocks
      }
      jobsCategory {
        nodes {
          uri
          slug
          name
        }
      }
      jobsLocation {
        nodes {
          uri
          slug
          name
        }
      }
      seo {
        metaDesc
        title
        opengraphImage {
          localFile {
            publicURL
          }
        }
      }
    }
    frontpage: wpPage(isFrontPage: { eq: true }) {
      id
      blocks {
        __typename
        ...AllBlocks
      }
    }
  }
`
